import React, { FC, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Menu } from 'antd';
import { useMount } from '@umijs/hooks';

interface Props {}

const SideMenu: FC<Props> = (props) => {
  let history = useHistory();
  const [paths, setPaths] = useState([]);
  useMount(()=>{
    resolve(history.location.pathname);
    history.listen(status => {
      const pathname = status.pathname;
      resolve(pathname);
    });
  });

  const resolve = (pathname) => {
    let paths = pathname.split('/').filter(v=>!!v);
    if(paths.length===0){
      paths.push('index')
    }else{
      paths = paths.splice(0, 1);
    }
    setPaths(paths);
  };

  return (
    <Menu theme="dark" selectedKeys={paths} mode="inline">
      <Menu.Item key="index">
        <Link to="/">
          首页
        </Link>
      </Menu.Item>
      <Menu.Item key="article">
        <Link to="/article">
          文章管理
        </Link>
      </Menu.Item>
      <Menu.Item key="catalogue">
        <Link to="/catalogue">
          分类管理
        </Link>
      </Menu.Item>
      <Menu.Item key="label">
        <Link to="/label">
          标签管理
        </Link>
      </Menu.Item>
      <Menu.Item key="banner">
        <Link to="/banner">
          Banner管理
        </Link>
      </Menu.Item>
      <Menu.Item key="comment">
        <Link to="/comment">
          评论管理
        </Link>
      </Menu.Item>
      <Menu.Item key="links">
        <Link to="/links">
          友链管理
        </Link>
      </Menu.Item>
      <Menu.Item key="music">
        <Link to="/music">
          音乐分享
        </Link>
      </Menu.Item>
      <Menu.Item key="times">
        <Link to="/times">
          旧时光
        </Link>
      </Menu.Item>
      <Menu.Item key="space">
        <Link to="/space">
          随心记
        </Link>
      </Menu.Item>
      <Menu.Item key="book">
        <Link to="/book">
          藏书阁
        </Link>
      </Menu.Item>
      <Menu.Item key="food">
        <Link to="/food">
          美食记
        </Link>
      </Menu.Item>
      <Menu.Item key="setting">
        <Link to="/setting">
          站点管理
        </Link>
      </Menu.Item>
    </Menu>
  );
};

export default SideMenu;
