import { GlobalStore } from './GlobalStore';
import React from 'react';
import { MobXProviderContext } from 'mobx-react';

export interface Stores{
  globalStore: GlobalStore
}

export function useStores():Stores{
  return React.useContext(MobXProviderContext) as any;
}