import React, { FC, useEffect, useRef, useState } from 'react';
import styles from './Login.module.scss';
import { Button, Form, Input } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useMount } from '@umijs/hooks';
import { FormInstance } from 'antd/es/form';
import { useStores } from '../../stores/tools';
import Loading from './Loading';

const $ = (window as any).$;

const Login: FC = () => {

  const formRef = useRef<FormInstance>();
  const [ready, setReady] = useState(false);
  const {globalStore} = useStores();
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    if(!loading){
      setTimeout(()=>{
        let initd = false;
        $('#panel').slideVerify({
          baseUrl:'',  //服务器请求地址, 默认地址为安吉服务器;
          mode:'pop',     //展示模式
          containerId:'loginBtn',//pop模式 必填 被点击之后出现行为验证码的元素id
          imgSize : {       //图片的大小对象,有默认值{ width: '310px',height: '155px'},可省略
            width: '400px',
            height: '200px',
          },
          barSize:{          //下方滑块的大小对象,有默认值{ width: '310px',height: '50px'},可省略
            width: '400px',
            height: '40px',
          },
          beforeCheck:function(){  //检验参数合法性的函数  mode ="pop"有效
            if(!initd){
              return true;
            }
            if(!formRef.current){
              return false;
            }
            let values = formRef.current.getFieldsValue();
            if(!values.account || !values.password){
              formRef.current.validateFields();
              return false;
            }
            return true;
          },
          ready : function() {
            setReady(true);
            initd = true;
          },  //加载完毕的回调
          success : function(params) { //成功的回调
            let values = formRef.current.getFieldsValue();
            values.verification = params.captchaVerification;
            doLogin(values);
          },
          error : function() {}        //失败的回调
        });
      }, 200);
    }
    // eslint-disable-next-line
  },[loading]);

  useMount(()=>{
    globalStore.checkLogin(()=>{
      setLoading(false);
    })
  });

  const doLogin = (params) => {
    globalStore.doLogin(params);
  };

  if(loading){
    return (
      <Loading/>
    )
  }

  return (
    <div className={styles.page}>
      <div className={styles.form}>
        <h2>陌上小筑-管理后台</h2>
        <Form ref={formRef}>
          <Form.Item name="account"
                     rules={[{required: true, message: '请输入用户名'}]}
                     >
            <Input
              placeholder="请输入用户名"
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }}/>}/>
          </Form.Item>
          <Form.Item name="password"
                     rules={[{required: true, message: '请输入密码'}]}
          >
            <Input.Password
              placeholder="请输入密码"
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }}/>}/>
          </Form.Item>
          <Form.Item>
            <Button type="primary" block id="loginBtn" disabled={!ready}>登录</Button>
          </Form.Item>
        </Form>
      </div>
      <div id="panel" style={{marginTop: 50}}/>
    </div>
  );
};

export default Login;
