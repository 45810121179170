import React, { FC } from 'react';
import styles from './NotFound.module.scss';
import svg from 'asserts/404.svg';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

const NotFound: FC = () => {

  return (
    <div className={styles.page}>
      <div className={styles.icon}>
        <img src={svg} alt="" />
      </div>
      <div className={styles.content}>
        <h1>404</h1>
        <div className={styles.desc}>
          抱歉，您访问的页面不存在
        </div>
        <div className={styles.actions}>
          <Link to="/">
            <Button type="primary">返回首页</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
