import React, { FC } from 'react';
import { useStores } from './stores/tools';
import {useObserver} from 'mobx-react';
import { Portal } from 'react-portal';
import { ToastContainer } from 'react-toastify';
import './App.scss';
import {Layout} from 'antd';
import Login from './pages/common/Login';
import SideMenu from './layout/SideMenu';
import Routers from './layout/Routers';

const {Sider, Content} = Layout;

const App: FC = () => {
  const {globalStore} = useStores();

  const {
    ready
  } = useObserver(()=>({
    ready: globalStore.ready
  }));

  if(ready){
    return (
      <div className="app">
        <Layout style={{height: '100%'}}>
          <Sider>
            <SideMenu/>
          </Sider>
          <Content>
            <Routers/>
          </Content>
        </Layout>
        <Portal>
          <ToastContainer/>
        </Portal>
      </div>
    )
  }else{
    return (
      <>
        <Login/>
        <Portal>
          <ToastContainer/>
        </Portal>
      </>
    )
  }
};

export default App;
