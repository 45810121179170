import qs from 'qs';
import { IResponse } from '../types';
import { message } from 'antd';

export function Get(url: string, param?: object, hideErrorMsg?: boolean): Promise<IResponse> {
  const queryString = parseParam(param);
  return doFetch(
    `${url}?${queryString}`,
    {
      credentials: 'include',
      method: 'GET',
    },
    hideErrorMsg,
  );
}

export function Post(url: string, data: object, hideErrorMsg?: boolean): Promise<IResponse> {
  return doFetch(
    url,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
    hideErrorMsg,
  );
}

export function PostForm(url: string, data: any, hideErrorMsg?: boolean): Promise<IResponse> {
  const param = parseParam(data, true);
  return doFetch(
    url,
    {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: param,
    },
    hideErrorMsg,
  );
}

export function PostFile(url: string, file: any, onProcess?: (percent: number) => void): Promise<IResponse> {
  let formData = new FormData();
  formData.append('file', file);
  return new Promise<IResponse>((resolve, reject) => {
    if (file.size > 50 * 1024 * 1024) {
      resolve({
        code: 413,
        msg: '',
        data: '',
        success: false,
      });
      return;
    }
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve(JSON.parse(xhr.responseText));
        } else {
          reject(xhr.responseText);
        }
      }
    };
    if (onProcess) {
      xhr.upload.onprogress = ev => {
        if (ev.lengthComputable) {
          let percent = (100 * ev.loaded) / file.size;
          if (percent > 100) {
            percent = 100;
          }
          onProcess(percent);
        }
      };
    }
    xhr.onerror = () => {
      resolve({
        code: 500,
        data: null,
        msg: '',
        success: false,
      });
    };
    xhr.onabort = () => {
      resolve({
        code: 500,
        data: null,
        msg: '',
        success: false,
      });
    };
    xhr.send(formData);
  });
}

export function Put(url: string, data: object, hideErrorMsg?: boolean): Promise<IResponse> {
  return doFetch(
    url,
    {
      credentials: 'include',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
    hideErrorMsg,
  );
}

export function Patch(url: string, data: object, hideErrorMsg?: boolean): Promise<IResponse> {
  return doFetch(
    url,
    {
      credentials: 'include',
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
    hideErrorMsg,
  );
}

export function PatchForm(url: string, data: any, hideErrorMsg?: boolean): Promise<IResponse> {
  const param = parseParam(data, true);
  return doFetch(
    url,
    {
      credentials: 'include',
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: param,
    },
    hideErrorMsg,
  );
}

export function Del(url: string, param?: object, hideErrorMsg?: boolean): Promise<IResponse> {
  const queryString = parseParam(param);
  return doFetch(
    `${url}?${queryString}`,
    {
      credentials: 'include',
      method: 'DELETE',
      headers: {},
    },
    hideErrorMsg,
  );
}

function parseParam(param?: any, form: boolean = false) {
  let obj;
  const type = typeof param;
  if (type === 'string' || type === 'number' || type === 'boolean') {
    obj = {};
    obj[param] = null;
  } else if (type === 'undefined' || param === null) {
    obj = {};
  } else {
    obj = param;
  }
  if (!form) {
    obj.t = Date.now();
  }
  return qs.stringify(obj);
}

function doFetch(url: string, param: RequestInit, hideErrorMsg: boolean): Promise<IResponse> {
  return new Promise<IResponse>((resolve, reject) => {
    fetch(url, param).then(
      res => {
        if (res.status === 200) {
          const json = res.json();
          json.then(t => {
            if (t.code !== 200) {
              if (!hideErrorMsg) {
                message.warn(`请求失败，错误码：${t.code}, 异常信息：${t.msg}`)
              }
            }
          });
          resolve(json);
        } else {
          message.warn(`请求失败，错误码：${res.status}`)
          reject(res);
        }
      },
      e => {
        message.error('未知异常')
        console.error(e);
        reject(e);
      },
    );
  });
}
