import { observable, action, runInAction } from 'mobx';
import { IResponse } from '../types';
import { Get, Post } from '../utils/http';

export class GlobalStore{
  @observable ready:boolean = false;

  @action checkLogin = (cb?:any) => {
    Get(`/api/admin/auth/check`, null, true)
      .then((data:IResponse)=>{
        if(data.code===200){
          runInAction(()=>{
            this.ready = true;
          })
        }else{
          cb && cb();
        }
      },()=>{})
  };

  @action doLogin = (params) => {
    Post(`/api/admin/auth/login`, params)
      .then((data:IResponse)=>{
        if(data.code===200){
          runInAction(()=>{
            this.ready = true;
          })
        }
      })
  };
}

export default new GlobalStore();