import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'mobx-react';
import {initStore} from 'stores';
import {ConfigProvider} from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import App from './App';
import {createBrowserHistory} from 'history';
import {Router} from 'react-router-dom';
import 'mobx-react-lite/batchingForReactDom';
import { configure } from 'mobx';

moment.updateLocale('zh-cn', { week: { dow: 0, doy: 4 } });
const stores = initStore();
const history = createBrowserHistory();
configure({enforceActions: 'observed'});

ReactDOM.render(
  <Provider {...stores}>
    <Router history={history}>
      <ConfigProvider locale={zhCN}>
        <App/>
      </ConfigProvider>
    </Router>
  </Provider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
