import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

import NotFound from '../pages/common/NotFound';
import SuspenseWrapper from 'components/SuspenseWrapper';

const Home = SuspenseWrapper(React.lazy(()=>import("pages/home")));
const Article = SuspenseWrapper(React.lazy(()=>import( 'pages/article')));
const Catalogue = SuspenseWrapper(React.lazy(()=>import( 'pages/catalogue')));
const Label = SuspenseWrapper(React.lazy(()=>import( 'pages/label')));
const Setting = SuspenseWrapper(React.lazy(()=>import( 'pages/setting')));
const Comment = SuspenseWrapper(React.lazy(()=>import( 'pages/comment')));
const Banner = SuspenseWrapper(React.lazy(()=>import( 'pages/banner')));
const Links = SuspenseWrapper(React.lazy(()=>import( 'pages/links')));
const Music = SuspenseWrapper(React.lazy(()=>import( 'pages/music')));
const Times = SuspenseWrapper(React.lazy(()=>import( 'pages/times')));
const BookList = SuspenseWrapper(React.lazy(()=>import( 'pages/book')));
const SpacePage = SuspenseWrapper(React.lazy(()=>import( 'pages/space')));
const FoodPage = SuspenseWrapper(React.lazy(()=>import( 'pages/food')));

const Routers: FC = () => {

  return (
    <Switch>
      <Route path="/" component={Home} exact/>
      <Route path="/article" component={Article}/>
      <Route path="/catalogue" component={Catalogue}/>
      <Route path="/label" component={Label}/>
      <Route path="/comment" component={Comment}/>
      <Route path="/setting" component={Setting}/>
      <Route path="/banner" component={Banner}/>
      <Route path="/links" component={Links}/>
      <Route path="/music" component={Music}/>
      <Route path="/times" component={Times}/>
      <Route path="/book" component={BookList}/>
      <Route path="/space" component={SpacePage}/>
      <Route path="/food" component={FoodPage}/>
      <Route component={NotFound}/>
    </Switch>
  );
};

export default Routers;
